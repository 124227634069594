import { Button, Modal, Space } from "antd";
import Timer from "../ShowTimer/ShowTimer";
// import { useEffect } from "react";

const ErrorModal = ({ message, OnCancel, visible, ShowTimer }) => {
  console.log(ShowTimer);
  return (
    <Modal
      visible={visible}
      closable={true}
      footer={null}
      onCancel={() => OnCancel()}
    >
      <div>
        <h3 style={{ textAlign: "center" }}>{message}</h3>
        {ShowTimer ? <Timer OnCancel={() => OnCancel()} /> : null}
      </div>
    </Modal>
  );
};

export default ErrorModal;
