import React from "react";
export default function Timer({ OnCancel }) {
  // Second Attempts
  const [timer, setTimer] = React.useState(32);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  React.useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      OnCancel();
      clear();
    }
  }, [timer]);

  return (
    <div className="timer">
      <div style={timer < 5 ? { color: "red" } : { color: "black" }}>
        Seconds Remaining: {timer}
      </div>
    </div>
  );
}
