import { Spin } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Main from "../../components/Main/Main";
// import logogamofyy from "../../assets/images/logo.png";
import gameomania from "../../assets/images/gameofyy.png";
import ShowLoginInput from "../../components/ShowLoginInput/ShowLoginInput";

const HomePage = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  let status = new URLSearchParams(search).get("userExist");
  let servicename = new URLSearchParams(search).get("servicename");
  let ani = new URLSearchParams(search).get("msisdn");

  let last_billed_date = new URLSearchParams(search).get("last_billed_date");
  let price = new URLSearchParams(search).get("price");
  let message = new URLSearchParams(search).get("message");

  let product_id = new URLSearchParams(search).get("product_id");
  let service_id = new URLSearchParams(search).get("service_id");
  let m_act = new URLSearchParams(search).get("m_act");
  let clickid = new URLSearchParams(search).get("clickid");

  console.log("status===>>>>>> ", status);
  const queryParams = new URLSearchParams(window.location.search);

  console.log("PARAMS  HOME APGE", queryParams.get("ani"));

  // useEffect(() => {
  //   if (status === "1")
  //     return navigate(
  //       `/gameomania/redirect/?ani=${ani}&serviceName=${servicename}?product_id=${product_id}&service_id=${service_id}&price=${price}&id=${clickid}`
  //     );
  // }, [status]);

  // useEffect(() => {
  //   if (ani === "123456789") setShowMenu(true);
  //   else setShowMenu(false);
  // }, []);

  // if (status === "true")
  //   return navigate(
  //     `/redirect/?ani=${ani}&serviceName=${serviceName}?product_id=${product_id}&service_id=${service_id}&price=${price}&id=${id}`
  //   );
  console.log(showMenu);

  return (
    <StyledHomePage>
      <Suspense
        fallback={
          <>
            <Spin />
          </>
        }
      >
        {showMenu ? (
          <ShowLoginInput
            serviceType={servicename}
            logo={gameomania}
            price={price}
            product_id={product_id}
            service_id={service_id}
            ani={queryParams.get("ani")}
            clickid={clickid}
          />
        ) : (
          <Main
            serviceType={servicename}
            logo={gameomania}
            price={price}
            product_id={product_id}
            service_id={service_id}
            ani={queryParams.get("ani")}
            clickid={clickid}
            isHeaderFlow={queryParams.get("ani") !== "null" ? true : false}
          />
        )}
      </Suspense>
    </StyledHomePage>
  );
};

export default HomePage;

const StyledHomePage = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
`;
