import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import userContext from "../context/UserContext";
import { Spin } from "antd";
import ErrorModal from "../ErrorModal/ErrorModal";
import Header from "../Header/Header";

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);

  console.log("PARAMS ", queryParams.get("ani"));
  console.log("status ", queryParams.get("status"));

  console.log(location);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [cookies, setCookie] = useCookies(["gameoffy"]);
  const { state } = React.useContext(userContext);

  const { status } = state;
  const cookiesNew = new Cookies();
  // console.log(cookies);

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  // console.log("coookies===>>)))) ", cookies);
  // console.log("coookies===>> ", cookies.gameoffy);
  // console.log("coookies===>>  type ofd ", typeof cookies.gameoffy);
  console.log("cookiesNew get ", cookiesNew.get("gameoffy"));
  useEffect(() => {
    console.log(" UPDATED COOKIE ", getCookie("gameoffy"));
    const fetchUserStatus = async () => {
      setLoading(false);
      try {
        const response = await axios.get(
          `https://api.afg.toon-flix.com/user/register?ani=${cookiesNew.get(
            "gameoffy"
          )}&service_type=GAMEOFFY&isSubscribe=false&pack_type=Gameoffy Weekly&mode=web`
        );
        console.log("USER STATUS", response.data);
        return response.data;
      } catch (err) {
        setLoading(false);
        console.log("USER ERROR", err.response);
        return navigate("/gameoffy/home");
      }
    };
    if (queryParams.get("status") === "1") {
      var tomorrow = new Date();
      var today = new Date();
      tomorrow.setDate(today.getDate() + 1);

      setLoading(false);
      cookiesNew.set("gameoffy", queryParams.get("ani"), {
        path: "/",
        expires: tomorrow,
      });
      return navigate("/gameoffy/redirect");
    } else if (
      cookiesNew.get("gameoffy") !== undefined &&
      cookiesNew.get("gameoffy") !== "undefined"
    ) {
      fetchUserStatus().then((data) => {
        console.log("Fetch status user ", data);
        if (data.length < 1) return navigate("/gameoffy/home");
        else return navigate("/gameoffy/redirect");
      });
      // } else if (cookies.gameoffy !== "undefined") {
      //   fetchUserStatus().then((data) => {
      //     console.log("Fetch status user ", data);
      //     if (data.length < 1) return navigate("/gameoffy/home");
      //     else return navigate("/gameoffy/redirect");
      //   });
    } else {
      setLoading(false);
      console.log(" I AM HERE NOW");
      return navigate("/gameoffy/home");
    }
  }, [cookiesNew.get("gameoffy")]);

  // useEffect(() => {
  //   if (cookies.gameoffy === undefined && queryParams.get("status") === null) {
  //     setLoading(false);
  //     return navigate("/gameoffy/home");
  //   } else if (
  //     queryParams.get("status") === "0" &&
  //     cookies.gameoffy === undefined
  //   ) {
  //     setLoading(false);
  //     return navigate(
  //       `/gameoffy/home/?ani=${queryParams.get("ani")}&status=${queryParams.get(
  //         "status"
  //       )}`
  //     );
  //   } else {
  //     if (queryParams.get("status") === "1") {
  //       setLoading(false);
  //       navigate("/gameoffy/redirect");
  //     } else if (cookies.gameoffy !== undefined) {
  //       console.log("HEEEEEE");
  //       setLoading(false);
  //       navigate("/gameoffy/redirect");
  //     }
  //   }
  // }, []);
  if (loading)
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <div>
      {!location.pathname.includes("home") ? <Header /> : null}
      {cookiesNew.get("gameoffy") === "null" &&
      location.pathname !== "/home" ? (
        <ErrorModal message="Invalid User" />
      ) : null}
      <div className="content">{props.children}</div>
    </div>
  );
};

export default Layout;
