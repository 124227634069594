import React, { useContext } from "react";
import styled from "styled-components";
import userContext from "../context/UserContext";

const SideBar = ({ collapsed }) => {
  const { state } = useContext(userContext);

  console.log(state);

  const { games, category } = state;
  console.log(games);
  return (
    <SideBarStyled collapsed={collapsed}>
      <div className="menu-items">
        <h1>Welcome</h1>
      </div>
    </SideBarStyled>
  );
};

export default SideBar;

const SideBarStyled = styled.div`
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  position: fixed;
  z-index: 3;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  right: ${(props) => (props.collapsed ? "100%" : "50%")};
  transition: right 0.5s ease-in;
  .menu-items {
    display: ${(props) => (props.collapsed ? "none" : "flex")};
    transition: display 0.7s ease-in-out;
    margin-top: 100px;
    justify-content: center;
    align-items: center;

    h3 {
    }
  }
`;
