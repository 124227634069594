import React, { useState } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = () => {
  let [color, setColor] = useState("cyan");
  return (
    <ClimbingBoxLoader
      color={color}
      loading={true}
      css={override}
      size={25}
      margin={2}
    />
  );
};

export default Spinner;
